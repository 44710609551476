module.exports = {
    copySuccess: "複製成功",
    emptyText: "沒有數據",
    invite: {
        copyLink: "複製連結",
        dailyRewardsWillBeCalculatedBasedOnYourSubordinate: "3.每日獎勵依下屬每日挖礦產出計算",
        example: "例子",
        example4: "透過各種社群軟體分享您的專屬推廣連結。\n好友透過您的專屬連結加入節點，您將獲得好友收益（8.00%）。\n你的朋友邀請他的朋友加入節點，你可以獲得他朋友的收益（8.00%）",
        invitationReward: "邀請獎勵",
        invite: "邀請",
        inviteAndReferYourFriendsToJoinYouWillGetUpToThreeTiersOfRewards: "1. 邀請並推薦您的朋友加入 - 您將獲得最多三層獎勵",
        newGenerationMobileNodeMining: "新一代移動節點挖礦",
        theThreeTierRewardIsLevel2: "2、三級獎勵為一級（8.00%）二級（8.00%）"
    },
    languages: {
        settings: "語言設定"
    },
    notConnnect: "沒有連接錢包",
    notData: "- 沒有相關數據 -",
    pullReload: "下拉刷新",
    record: {
        approved: "審核通過",
        describe: "描述",
        earningDetails: "收入明細",
        quantity: "數量",
        teamDetails: "團隊",
        time: "時間",
        withdrawalDetails: "取款詳情"
    },
    secondary: "次",
    tabBar: {
        home: "首頁",
        investment: "投資",
        mine: "我的",
        service: "服務",
        team: "團隊"
    },
    team: {
        registrationTime: "註冊時間",
        userRevenue: "用戶收入"
    },
    unline: "~ 沒有更多數據了 ~",
    updates: "釋放更新",
    userCenter: {
        cancel: "取消",
        cancelled: "您已取消",
        confirm: "確認",
        earnCommission: "賺取佣金",
        inputWithdrawAmount: "請輸入提款金額",
        insufficientFunds: "資金不足，無法提現",
        invite: "邀請",
        inviteFriends: "邀請好友",
        loading: "加載中",
        max: "最大",
        minerFeeNotice: "您需要支付礦工費用以獲得收益，請確保錢包中有足夠的BNB作為礦工費。",
        verificationSentToTrustWallet: "驗證發送至 Trust Wallet。\n請檢查並處理！",
        withdraw: "提現",
        withdrawWallet: "提現錢包",
        withdrawalAmount: "提現金額",
        withdrawalRecord: "提現記錄"
    },
    userIndex: {
        address: "地址",
        currentHashrate: "當前算力",
        hashAid: "哈希助手",
        helpCenter: "幫助中心",
        invite: "邀請",
        mining: "挖礦",
        miningTips: "當前錢包餘額為0，每天可獲得0收入。\n您可以隨時增加錢包餘額",
        participant: "參與者",
        participants: "參加者",
        participate: "參與",
        quantity: "數量",
        receive: "收到",
        stakingAPY: "日收益率",
        stop: "停止",
        todayOutput: "今日產出",
        totalOutput: "總產出",
        totalParticipation: "總參與人數",
        userBenefits: "用戶收益",
        userRevenue: "用戶收益",
        walletBalance: "錢包餘額",
        yesterdayOutput: "昨日產出"
    },
    userService: {
        cooperationPlatform: "合作平台",
        defiPlatform: "致力於打造全球最大的DEX、IMO和DAO一體化的DeFi平台",
        gradingIncome: "分級收益",
        income: "收益",
        level: "等級",
        lowEntryBarrier: "低門檻",
        noNeedTransfer: "無需轉幣",
        professionalStability: "專業穩定",
        projectFeatures: "項目特點",
        safeAndSecure: "安全可靠",
        sharingNodeMiningRevenue: "分享節點挖礦收益",
        stableIncome: "穩定收益",
        stableOperation: "專業團隊，全年穩定運行",
        usdEthwAiStrategyC: "USD ETHW AI策略-C礦池",
        usdtDeposit: "無需轉幣，USDT存入自己的錢包0風險",
        yield: "收益率"
    },
    userTeam: {
        myEarnings: "我的收益",
        myTeam: "我的團隊",
        people: "人",
        seeAll: "查看全部",
        todayEarnings: "今日收益",
        totalPeople: "總人數",
        totalRevenue: "總收益",
        yesterdayEarnings: "昨日收益"
    },
    viewDetail: "查看詳情",
    kef: "客服",
    helpCenter:[
        {
            title: "礦工中獎公告",
            content: "礦工中獎公告對象：所有成功報名挖礦的會員規則：公司節點挖礦系統每週抽取六位幸運兒時間：2024.1.1~~2024.12.31 一等獎（1人）：177777 USDT 二等獎（ 2人） ）：80000 USDT 三等獎（3人）：30000 USDT 以下獎勵數量詳情請聯絡線上客服：1777 2777 3777 5777 7777 17777 兌換條件：一等獎需18,888 USDT88 USDT參與挖礦7,777 USDT參與挖礦，三等獎需要5,555 USDT參與挖礦。"
        },
        {
            title: "什麼是（USDT）？",
            content: "USDT是英文Decentralized Finance的全名，即“去中心化金融”，也稱為“開放金融”。資本市場，目前幾乎所有的DeFi專案都在以太坊區塊鏈上進行。"
        },
        {
            title: "如何加入（USDT）智慧挖礦？",
            content: "1.首先您需要在手機上下載幣安交易所和Trust錢包並完成註冊。每日收益） ).3.(USDT)挖礦是智慧的，不需要自己挖礦。"
        },
        {
            title: "USDT有風險嗎？",
            content: "USDT是基於區塊鏈的應用生態系統。現在可以鎖定他們的資金資金或使用它們在一系列去中心化平台上提供流動性並定期收取利息。"
        },
        {
            title: "資金安全嗎？",
            content: "我們使用幣安，它被認為是業內最安全的加密貨幣交易所之一。數位和法定存款保險計畫其中一些安全功能是由使用者強制執行的，以保護其帳戶免遭未經授權的存取並延遲任何未經授權的交易或轉帳。洩漏您的私鑰，就會有12個助記詞，你是世界上唯一可以交易和控制它的人。"
        },
        {
            title: "如何戒菸？",
            content: "我們使用幣安，它被認為是業內最安全的加密貨幣交易所之一。數位和法定存款保險計畫其中一些安全功能是由使用者強制執行的，以保護其帳戶免遭未經授權的存取並延遲任何未經授權的交易或轉帳。洩漏您的私鑰，就會有12個助記詞，你是世界上唯一可以交易和控制它的人。"
        },
        {
            title: "為什麼我們需要付錢給礦工才能套現利潤？",
            content: "因為在參與挖礦的過程中，客戶只算名字，而礦工費已經由礦工支付了。產生的收益實際上是在合約池中，只有礦工費才能換取收益，只需支付一次。"
        },
        {
            title: "我該如何參與？",
            content: "1、創建一個trust錢包。2.透過Google Chrome進入官網，連結wallettrust polychain 3.將polyne連接到USDT並存入錢包 4.您的錢包必須有10USDT以上的polyne作為挖礦費才能參與本專案。"
        },
        {
            title: "什麼是USDT流動性挖礦？",
            content: "流動性挖礦是Defi去中心化金融的一種，不需要購買顯示卡，貢獻算力，也不需要支付昂貴的電費，而是透過提供資金和流動性來獲得收入。大，對於一些堅定的持幣者，流動性挖礦是一種無需交易即可獲得收入並積累更多加密貨幣的方式，參與本項目吧。"
        }
    ]
}