import { createApp } from 'vue'
import vue from 'vue'
import App from './App.vue'
import router from './router'
document.title =  'GoldenCoin'

import { i18n } from '@/utils/i18n.js'
import global from '@/utils/global.js'
import 'vant/lib/index.css'
import { Tab, Tabs,Tabbar, TabbarItem,Icon,Collapse, CollapseItem } from 'vant';
import { Swipe, SwipeItem } from 'vant';

import { showSuccessToast, showFailToast,showToast,Dialog } from 'vant';
import Vue3Storage from "vue3-storage";
import VueClipboard from 'vue-clipboard2'
const app = createApp(App).use(i18n).use(global).use(router).use(Tab).use(Tabs).use(Swipe).use(SwipeItem).use(Tabbar).use(TabbarItem).use(Icon).use(Collapse).use(CollapseItem).use(VueClipboard).use(Vue3Storage)
app.config.globalProperties.$showToast = showToast;
app.config.globalProperties.$showSuccessToast = showSuccessToast;
app.config.globalProperties.$showFailToast = showFailToast;
app.config.globalProperties.$Dialog =Dialog;
app.config.globalProperties.$subAccount = localStorage.getItem('subAccount') || '';
app.config.globalProperties.$global = global;
app.mount('#app')
