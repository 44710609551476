module.exports = {
    copySuccess: "Salinan berhasil",
    emptyText: "Tidak ada data",
    invite: {
        copyLink: "Salin tautan",
        dailyRewardsWillBeCalculatedBasedOnYourSubordinate: "3. Hadiah Harian akan Dihitung Berdasarkan Hasil Penambangan Harian Bawahan Anda",
        example: "Contoh",
        example4: "Bagikan tautan promosi eksklusif Anda melalui berbagai perangkat lunak sosial. \nTeman bergabung dengan node melalui tautan eksklusif Anda, dan Anda akan mendapatkan penghasilan teman (8,00%). \nTeman anda mengajak temannya untuk bergabung di node tersebut, anda bisa mendapatkan penghasilan temannya (8.00%)",
        invitationReward: "Hadiah Undangan",
        invite: "Mengundang",
        inviteAndReferYourFriendsToJoinYouWillGetUpToThreeTiersOfRewards: "1. Undang dan Referensikan Teman Anda untuk Bergabung - Anda Akan Mendapatkan Hadiah Hingga Tiga Tingkat",
        newGenerationMobileNodeMining: "Penambangan Node Seluler Generasi Baru",
        theThreeTierRewardIsLevel2: "2. Hadiah Tiga Tingkat adalah Level 1 (8,00%) Level 2 (8,00%)"
    },
    languages: {
        settings: "Pengaturan Bahasa"
    },
    notConnnect: "Tidak ada dompet yang terhubung",
    notData: "- Tidak ada data yang relevan -",
    pullReload: "Tarik ke bawah untuk menyegarkan",
    record: {
        approved: "Disetujui",
        describe: "menggambarkan",
        earningDetails: "Detail pendapatan",
        quantity: "kuantitas",
        teamDetails: "Tim",
        time: "waktu",
        withdrawalDetails: "Detail penarikan"
    },
    secondary: "Kelas dua",
    tabBar: {
        home: "halaman Depan",
        investment: "menginvestasikan",
        mine: "milikku",
        service: "Melayani",
        team: "tim"
    },
    team: {
        registrationTime: "Waktu Pendaftaran",
        userRevenue: "Pendapatan Pengguna"
    },
    unline: "~ Tidak ada data lagi ~",
    updates: "Rilis pembaruan",
    userCenter: {
        cancel: "Membatalkan",
        cancelled: "Anda telah membatalkan",
        confirm: "mengonfirmasi",
        earnCommission: "mendapatkan komisi",
        inputWithdrawAmount: "Silakan masukkan jumlah penarikan",
        insufficientFunds: "Dana tidak cukup untuk penarikan",
        invite: "mengundang",
        inviteFriends: "mengundang teman-teman",
        loading: "memuat",
        max: "maksimum",
        minerFeeNotice: "Anda perlu membayar biaya penambang untuk mendapatkan manfaatnya, pastikan Anda memiliki cukup BNB di dompet Anda sebagai biaya penambang.",
        verificationSentToTrustWallet: "Verifikasi dikirim ke Trust Wallet. \nSilakan periksa dan proses!",
        withdraw: "menarik",
        withdrawWallet: "Tarik dompet",
        withdrawalAmount: "Jumlah penarikan",
        withdrawalRecord: "Catatan penarikan"
    },
    userIndex: {
        address: "alamat",
        currentHashrate: "Kekuatan komputasi saat ini",
        hashAid: "Pembantu Hash",
        helpCenter: "Pusat Bantuan",
        invite: "mengundang",
        mining: "Pertambangan",
        miningTips: "Saldo dompet saat ini adalah 0, dan Anda bisa mendapatkan penghasilan 0 setiap hari. \nAnda dapat menambah saldo dompet Anda kapan saja",
        participant: "peserta",
        participants: "Peserta",
        participate: "Ikut",
        quantity: "kuantitas",
        receive: "Menerima",
        stakingAPY: "Hasil harian",
        stop: "Berhenti",
        todayOutput: "Keluaran hari ini",
        totalOutput: "jumlah keluaran",
        totalParticipation: "jumlah total peserta",
        userBenefits: "Manfaat bagi pengguna",
        userRevenue: "Manfaat bagi pengguna",
        walletBalance: "saldo dompet",
        yesterdayOutput: "Keluaran kemarin"
    },
    userService: {
        cooperationPlatform: "Platform Kerja Sama",
        defiPlatform: "Berkomitmen untuk membangun platform DeFi terintegrasi terbesar di dunia yang terdiri dari DEX, IMO, dan DAO",
        gradingIncome: "Manfaat yang dinilai",
        income: "penghasilan",
        level: "nilai",
        lowEntryBarrier: "Ambang batas rendah",
        noNeedTransfer: "Tidak perlu mentransfer koin",
        professionalStability: "Profesional dan stabil",
        projectFeatures: "Fitur Proyek",
        safeAndSecure: "Aman dan dapat diandalkan",
        sharingNodeMiningRevenue: "Bagikan pendapatan penambangan node",
        stableIncome: "Pendapatan stabil",
        stableOperation: "Tim profesional, operasi stabil sepanjang tahun",
        usdEthwAiStrategyC: "Kumpulan Penambangan Strategi-C USD ETHW AI",
        usdtDeposit: "Tidak perlu mentransfer mata uang, USDT disimpan ke dompet Anda sendiri tanpa risiko",
        yield: "Menghasilkan"
    },
    userTeam: {
        myEarnings: "penghasilan saya",
        myTeam: "kelompok ku",
        people: "rakyat",
        seeAll: "Lihat semua",
        todayEarnings: "Penghasilan hari ini",
        totalPeople: "jumlah orang",
        totalRevenue: "Total pendapatan",
        yesterdayEarnings: "Penghasilan kemarin"
    },
    viewDetail: "periksa detailnya",
    kef: "pelayanan pelanggan",
    helpCenter:[
        {
            title: "Pengumuman Pemenang Miner",
            content: "Pengumuman Pemenang Penambang Objek: Semua anggota yang berhasil mendaftar untuk penambangan Aturan: Sistem penambangan node perusahaan mengundi enam pemenang yang beruntung setiap minggu Waktu: 2024.1.1~~2024.12.31 Hadiah pertama (1 orang): 177777 USDT Hadiah kedua (2 orang): 80000 USDT Hadiah ketiga (3 orang): 30000 USDT Silakan hubungi layanan pelanggan daring untuk rincian jumlah hadiah berikut: 1777 2777 3777 5777 7777 17777 Ketentuan penukaran: Hadiah pertama memerlukan 18.888 USDT untuk berpartisipasi dalam penambangan, hadiah kedua memerlukan 7.777 USDT untuk berpartisipasi dalam penambangan, dan hadiah ketiga memerlukan 5.555 USDT untuk berpartisipasi dalam penambangan."
        },
        {
            title: "Apa itu (USDT)?",
            content: "USDT adalah nama lengkap dari Decentralized Finance dalam bahasa Inggris, yaitu \"decentralized finance\", yang juga dikenal sebagai \"open finance\". USDT tidak memerlukan perantara tepercaya, dan penerima manfaatnya termasuk orang-orang yang sebelumnya tidak dapat memperoleh layanan keuangan tersebut. Oleh karena itu, USDT telah menjadi pasar modal yang matang, dan hampir semua proyek DeFi saat ini dijalankan di blockchain Ethereum."
        },
        {
            title: "Bagaimana cara bergabung dengan (USDT) Smart Mining?",
            content: "1. Pertama, Anda perlu mengunduh Binance Exchange dan Trust Wallet di ponsel Anda dan menyelesaikan pendaftaran. 2. Kemudian beli USDT di Binance Exchange dan transfer ke Trust Wallet Anda untuk mendapatkan pendapatan penambangan: 4% -20% (pendapatan harian). 3. Penambangan (USDT) cerdas, Anda tidak perlu menambang sendiri. 4. Anda hanya perlu menerima pendapatan yang dihasilkan oleh saldo dompet setiap hari."
        },
        {
            title: "Apakah USDT Berisiko?",
            content: "USDT adalah ekosistem aplikasi berbasis blockchain. Ia menawarkan berbagai layanan keuangan yang serupa dengan yang ditawarkan oleh bank tradisional, pialang asuransi, dan perantara keuangan lainnya. Mirip dengan mendepositokan dana ke rekening tabungan untuk memperoleh bunga, investor mata uang kripto kini dapat mengunci dana mereka atau menggunakannya untuk menyediakan likuiditas pada berbagai platform terdesentralisasi dan menerima bunga secara berkala."
        },
        {
            title: "Apakah dananya aman?",
            content: "Kami menggunakan Binance, yang dianggap sebagai salah satu bursa mata uang kripto paling aman dalam industri ini. Bursa ini memiliki keamanan berlapis, yang membuat kemungkinan terjadinya peretasan atau pencurian menjadi sangat rendah. Selain itu, bursa ini menanggung semua skema asuransi simpanan digital dan fiat yang disimpan dalam akun mereka. Beberapa fitur keamanan ini diberlakukan oleh pengguna untuk melindungi akun mereka dari akses tidak sah dan menunda segala transaksi atau transfer tidak sah. Akan ada 12 mnemonik saat Anda mendaftar dan masuk, selama Anda tidak mengungkapkan kunci pribadi Anda, Anda adalah satu-satunya orang di dunia yang dapat memperdagangkan dan mengendalikannya."
        },
        {
            title: "Bagaimana cara berhenti?",
            content: "Kami menggunakan Binance, yang dianggap sebagai salah satu bursa mata uang kripto paling aman dalam industri ini. Bursa ini memiliki keamanan berlapis, yang membuat kemungkinan terjadinya peretasan atau pencurian menjadi sangat rendah. Selain itu, bursa ini menanggung semua skema asuransi simpanan digital dan fiat yang disimpan dalam akun mereka. Beberapa fitur keamanan ini diberlakukan oleh pengguna untuk melindungi akun mereka dari akses tidak sah dan menunda segala transaksi atau transfer tidak sah. Akan ada 12 mnemonik saat Anda mendaftar dan masuk, selama Anda tidak mengungkapkan kunci pribadi Anda, Anda adalah satu-satunya orang di dunia yang dapat memperdagangkan dan mengendalikannya."
        },
        {
            title: "Mengapa kita perlu membayar penambang untuk menguangkan keuntungan?",
            content: "Karena dalam proses berpartisipasi dalam penambangan, pelanggan hanya menghitung nama, dan biaya penambang telah dibayarkan oleh penambang. Pendapatan yang dihasilkan sebenarnya ada dalam kumpulan kontrak, dan hanya biaya penambang yang dapat ditukar dengan pendapatan, yang hanya perlu dibayarkan satu kali."
        },
        {
            title: "Bagaimana cara saya berpartisipasi?",
            content: "1. Buat dompet kepercayaan 2. Masuk ke situs web resmi melalui Google Chrome dan tautkan ke wallettrust polychain 3. Hubungkan polyne ke USDT dan simpan ke dalam dompet 4. Dompet Anda harus memiliki lebih dari 10USDT polyne sebagai biaya penambangan untuk berpartisipasi dalam proyek ini."
        },
        {
            title: "Apa itu Penambangan Likuiditas USDT?",
            content: "Penambangan likuiditas merupakan salah satu jenis keuangan terdesentralisasi Defi. Penambangan ini tidak perlu membeli kartu grafis, menyumbangkan daya komputasi, atau membayar tagihan listrik yang mahal, tetapi memperoleh pendapatan dengan menyediakan dana dan likuiditas. Terutama akhir-akhir ini, harga mata uang mengalami fluktuasi yang sangat besar. Bagi sebagian pemegang mata uang setia, penambangan likuiditas merupakan cara untuk memperoleh pendapatan dan mengakumulasi lebih banyak mata uang kripto tanpa harus berdagang. Berpartisipasilah dalam proyek ini."
        }
    ]
}