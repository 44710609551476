<template>
  <van-tabbar
    class="s_footer_layout_container"
    :safe-area-inset-bottom="false"
    route
    active-color="rgb(107, 84, 235)"
    inactive-color="rgb(169, 171, 252)"
  >
    <van-tabbar-item
      v-for="(item, index) in list"
      :to="item.path"
      :key="index"
      class="s-tab-item"
    >
      <span>{{ $t(item.title) }}</span>
      <template #icon="props">
        <img :src="props.active ? item.img1 : item.img0" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: "tabBar.home",
          img0: require("../assets/img/tabbar/home.png"),
          img1: require("../assets/img/tabbar/home_s.png"),
          path: "/home",
        },
        {
          title: "tabBar.team",
          img0: require("../assets/img/tabbar/team.png"),
          img1: require("../assets/img/tabbar/team_s.png"),
          path: "/team",
        },
        {
          title: "tabBar.service",
          img0: require("../assets/img/tabbar/serve.png"),
          img1: require("../assets/img/tabbar/serve_s.png"),
          path: "/service",
        },
        {
          title: "tabBar.mine",
          img0: require("../assets/img/tabbar/mine.png"),
          img1: require("../assets/img/tabbar/mine_s.png"),
          path: "/mine",
        },
      ],
    };
  },
  created() {
    // for(let i in this.i18n.tabList){
    //   this.list.push({
    //     title: this.i18n.tabList[i].title,
    //     path: this.i18n.tabList[i].path,
    //     name: this.i18n.tabList[i].name,
    //     img0: require('../assets/img/footer/' + this.i18n.tabList[i].img + '.png'),
    //     img1: require('../assets/img/footer/' + this.i18n.tabList[i].img + '_s.png'),
    //   })
    // }
    // console.log("//////" , this.i18n.tabList);
  },
  methods: {},
  computed: {
    i18n() {
      return this.$t(`footerNav`);
    },
  },
};
</script>

<style lang="scss">
.s_footer_layout_container {
  background-color: #212244;
  &.van-tabbar {
    background-color: #212244;
    height: 60px;
    bottom: 0px;
    // bottom: constant(safe-area-inset-bottom); //苹果底部安全距离
    // bottom: env(safe-area-inset-bottom); //苹果底部安全距离
    .s-tab-item {
      font-size: 14px;
      margin-bottom: 10px;
      .van-tabbar-item__icon {
        .iconfont {
          font-size: 22px;
        }
      }
    }
  }

  .van-tabbar-item--active {
    background-color: #212244;
  }
  .van-tabbar-item__text {
    white-space: nowrap;
  }
  &.van-hairline--top-bottom::after,
  .van-hairline-unset--top-bottom::after {
    border-color: #00000054;
    border-width: 1px 0;
  }
}
</style>
