<template>
  <div id="app">
    <transition :name="transitionName" v-if="show">
      <router-view />
    </transition>
    <OnlinePerson />
  </div>
</template>

<script>
//   import Hello from './components/Hello'
import OnlinePerson from "./components/OnlinePerson.vue";
export default {
  data() {
    return {
      transitionName: "",
      show: true,
    };
  },
  name: "app",
  components: {
    OnlinePerson
    //   Hello
  },
};
</script>

<style lang="scss">
body,
html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // margin-top: 60px;
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}
</style>
