module.exports = {
    copySuccess: "Copia exitosa",
    emptyText: "Sin datos",
    invite: {
        copyLink: "Copiar link",
        dailyRewardsWillBeCalculatedBasedOnYourSubordinate: "3. Las recompensas diarias se calcularán en función de la producción minera diaria de su subordinado.",
        example: "Ejemplo",
        example4: "Comparta su enlace de promoción exclusivo a través de varios software sociales. \nLos amigos se unen al nodo a través de su enlace exclusivo y obtendrán ingresos por amigos (8,00%). \nTu amigo invita a su amigo a unirse al nodo, puedes obtener los ingresos de su amigo (8,00%)",
        invitationReward: "Recompensa por invitación",
        invite: "Invitar",
        inviteAndReferYourFriendsToJoinYouWillGetUpToThreeTiersOfRewards: "1. Invita y recomienda a tus amigos para que se unan: obtendrás hasta tres niveles de recompensas",
        newGenerationMobileNodeMining: "Minería de nodos móviles de nueva generación",
        theThreeTierRewardIsLevel2: "2. La recompensa de tres niveles es Nivel 1 (8,00%) Nivel 2 (8,00%)"
    },
    languages: {
        settings: "Ajustes de idioma"
    },
    notConnnect: "No hay billetera conectada",
    notData: "- Sin datos relevantes -",
    pullReload: "Tire hacia abajo para refrescar",
    record: {
        approved: "Aprobado",
        describe: "describir",
        earningDetails: "Detalles de ingresos",
        quantity: "cantidad",
        teamDetails: "equipos",
        time: "tiempo",
        withdrawalDetails: "Detalles de retiro"
    },
    secondary: "De segunda categoría",
    tabBar: {
        home: "página delantera",
        investment: "invertir",
        mine: "mío",
        service: "Atender",
        team: "equipo"
    },
    team: {
        registrationTime: "Hora de registro",
        userRevenue: "Ingresos del usuario"
    },
    unline: "~ No más datos ~",
    updates: "Actualizaciones de lanzamiento",
    userCenter: {
        cancel: "Cancelar",
        cancelled: "has cancelado",
        confirm: "confirmar",
        earnCommission: "ganar comisión",
        inputWithdrawAmount: "Por favor ingrese el monto del retiro",
        insufficientFunds: "Fondos insuficientes para el retiro",
        invite: "invitar",
        inviteFriends: "invitar a amigos",
        loading: "cargando",
        max: "máximo",
        minerFeeNotice: "Debe pagar la tarifa de minero para obtener los beneficios; asegúrese de tener suficiente BNB en su billetera como tarifa de minero.",
        verificationSentToTrustWallet: "Verificación enviada a Trust Wallet. \n¡Por favor verifique y procese!",
        withdraw: "retirar",
        withdrawWallet: "Retirar billetera",
        withdrawalAmount: "Cantidad de retiro",
        withdrawalRecord: "Registro de retiros"
    },
    userIndex: {
        address: "DIRECCIÓN",
        currentHashrate: "Potencia informática actual",
        hashAid: "Ayudante de hash",
        helpCenter: "Centro de ayuda",
        invite: "invitar",
        mining: "Minería",
        miningTips: "El saldo actual de la billetera es 0 y puede obtener 0 ingresos todos los días. \nPuede aumentar el saldo de su billetera en cualquier momento para",
        participant: "Participantes",
        participants: "Participantes",
        participate: "Participar",
        quantity: "cantidad",
        receive: "Recibir",
        stakingAPY: "Rendimiento diario",
        stop: "Detener",
        todayOutput: "Salida de hoy",
        totalOutput: "producción total",
        totalParticipation: "número total de participantes",
        userBenefits: "Beneficios para el usuario",
        userRevenue: "Beneficios para el usuario",
        walletBalance: "saldo de billetera",
        yesterdayOutput: "salida de ayer"
    },
    userService: {
        cooperationPlatform: "Plataforma de Cooperación",
        defiPlatform: "Comprometidos con la construcción de la plataforma DeFi integrada más grande del mundo de DEX, IMO y DAO",
        gradingIncome: "Beneficios graduados",
        income: "ingreso",
        level: "calificación",
        lowEntryBarrier: "Umbral bajo",
        noNeedTransfer: "No es necesario transferir monedas",
        professionalStability: "Profesional y estable",
        projectFeatures: "Características del proyecto",
        safeAndSecure: "Seguro y confiable",
        sharingNodeMiningRevenue: "Compartir los ingresos de la minería de nodos",
        stableIncome: "Ingresos estables",
        stableOperation: "Equipo profesional, funcionamiento estable todo el año.",
        usdEthwAiStrategyC: "USD ETHW AI Strategy-C Grupo de minería",
        usdtDeposit: "No es necesario transferir moneda, el USDT se deposita en su propia billetera sin riesgo",
        yield: "tasa de retorno"
    },
    userTeam: {
        myEarnings: "Mis Ganancias",
        myTeam: "mi equipo",
        people: "gente",
        seeAll: "ver todo",
        todayEarnings: "Las ganancias de hoy",
        totalPeople: "personas totales",
        totalRevenue: "Los ingresos totales",
        yesterdayEarnings: "Ganancias de ayer"
    },
    viewDetail: "comprobar los detalles",
    kef: "servicio al cliente",
    helpCenter:[
        {
            title: "Anuncio ganador del minero",
            content: "Anuncio de ganador del minero Objeto: Todos los miembros que se registraron exitosamente para la minería Reglas: El sistema de minería de nodos de la compañía sortea seis afortunados ganadores cada semana Hora: 2024.1.1~~2024.12.31 Primer premio (1 persona): 177777 USDT Segundo premio (2 personas ):80000 USDT Tercer premio (3 personas):30000 USDT Comuníquese con el servicio de atención al cliente en línea para obtener detalles sobre las siguientes cantidades de recompensa:1777 2777 3777 5777 7777 17777 Condiciones de canje:El primer premio requiere 18,888 USDT para participar en la minería, el segundo premio requiere 7,777 USDT para participar en minería, y el tercer premio requiere 5,555 USDT para participar en minería."
        },
        {
            title: "¿Qué es (USDT)?",
            content: "USDT es el nombre completo de Finanzas Descentralizadas en inglés, que es\"finanzas descentralizadas\", también conocido como\"finanzas abiertas\". USDT no requiere intermediarios confiables y entre los beneficiarios se incluyen personas que no pudieron obtener dichos servicios financieros. antes.Por lo tanto,USDT Se ha establecido un mercado de capitales maduro y casi todos los proyectos DeFi se llevan a cabo actualmente en la cadena de bloques Ethereum."
        },
        {
            title: "¿Cómo unirse a (USDT) Smart Mining?",
            content: "1. Primero, debe descargar Binance Exchange y Trust Wallet en su teléfono móvil y completar el registro. 2. Luego compre USDT en Binance Exchange y transfiéralo a su Trust Wallet para obtener ingresos mineros: 4% -20% (ingresos diarios ).3.La minería (USDT) es inteligente, no es necesario que la extraiga usted mismo.4.Solo necesita recibir los ingresos generados por el saldo de la billetera todos los días."
        },
        {
            title: "¿Es arriesgado el USDT?",
            content: "USDT es un ecosistema de aplicaciones basado en blockchain. Ofrece una gama de servicios financieros similares a los que ofrecen los bancos tradicionales, corredores de seguros y otros intermediarios financieros. De manera similar a depositar fondos en una cuenta de ahorros para ganar intereses, los inversores en criptomonedas ahora pueden bloquear sus fondos o utilizarlos para proporcionar liquidez en una variedad de plataformas descentralizadas y recibir intereses de forma regular."
        },
        {
            title: "¿Están seguros los fondos?",
            content: "Usamos Binance, que se considera uno de los intercambios de criptomonedas más seguros de la industria. Tiene seguridad en capas, lo que hace que las posibilidades de un hackeo o robo exitoso sean extremadamente bajas. Además, cubre todos los esquemas de seguro de depósitos digitales y fiduciarios almacenados en sus cuentas. Los usuarios aplican algunas de estas características de seguridad para proteger sus cuentas del acceso no autorizado y retrasar cualquier transacción o transferencia no autorizada. Y habrá 12 mnemónicos cuando se registre e inicie sesión, siempre y cuando no revele su clave privada. eres la única persona en el mundo que puede comercializarlo y controlarlo."
        },
        {
            title: "¿Cómo dejar de fumar?",
            content: "Usamos Binance, que se considera uno de los intercambios de criptomonedas más seguros de la industria. Tiene seguridad en capas, lo que hace que las posibilidades de un hackeo o robo exitoso sean extremadamente bajas. Además, cubre todos los esquemas de seguro de depósitos digitales y fiduciarios almacenados en sus cuentas. Los usuarios aplican algunas de estas características de seguridad para proteger sus cuentas del acceso no autorizado y retrasar cualquier transacción o transferencia no autorizada. Y habrá 12 mnemónicos cuando se registre e inicie sesión, siempre y cuando no revele su clave privada. eres la única persona en el mundo que puede comercializarlo y controlarlo."
        },
        {
            title: "¿Por qué necesitamos pagar a los mineros para retirar las ganancias?",
            content: "Porque en el proceso de participación en la minería, el cliente solo cuenta el nombre y los mineros han pagado la tarifa del minero. Los ingresos generados en realidad están en un grupo de contratos, y solo la tarifa del minero se puede cambiar por ingresos, que solo deben pagarse una vez."
        },
        {
            title: "¿Cómo participo?",
            content: "1.Cree una billetera de confianza 2.Ingrese al sitio web oficial a través de Google Chrome y vincule a la billeteratrust polychain 3.Conecte Polyne a USDT y deposítelo en la billetera 4.Su billetera debe tener más de 10 USDT de Polyne como tarifa de minería para participar en esto proyecto."
        },
        {
            title: "¿Qué es la minería de liquidez del USDT?",
            content: "La minería de liquidez es un tipo de financiación descentralizada de Defi. No necesita comprar tarjetas gráficas, aportar potencia informática ni pagar costosas facturas de electricidad, sino obtener ingresos proporcionando fondos y liquidez. Especialmente recientemente, el precio de la moneda ha fluctuado mucho. Para algunos Tenedores acérrimos de moneda, la minería de liquidez es una forma de obtener ingresos y acumular más criptomonedas sin necesidad de comerciar. Participa en este proyecto."
        }
    ]
}