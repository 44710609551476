
import { createApp } from 'vue'
import App from '../App.vue'
const Vue = createApp(App)
const VueI18n = require('vue-i18n')
Vue.use(VueI18n);
const locales = {
	zh: require('@/lang/zh-TW.js'),
	en: require('@/lang/en.js'),
	es: require('@/lang/es.js'),
	id: require('@/lang/id.js'),
	pt: require('@/lang/pt.js'),
	// zh: require('@/lang/zh-CN.js'),
	// tw: require('@/lang/zh-TW.js'),
	// en: require('@/lang/en.js'),
	// fr: require('@/lang/fr.js'),
	// de: require('@/lang/de.js'),
	// ja: require('@/lang/ja.js'),
	// ko: require('@/lang/ko.js'),
	// vi: require('@/lang/vi.js'),
	// th: require('@/lang/th.js'),
}
// export const i18n = new VueI18n({
// 	// locale: 'zh-CN',
// 	// messages
// 	messages: {
// 		'zh-CN': locales.zh,
// 		'zh-TW': locales.tw,
// 		// 'en': locales.en,
// 		// 'fr': locales.fr,
// 		// 'de': locales.de,
// 		// 'ja': locales.ja,
// 		// 'ko': locales.ko,
// 		// 'vi': locales.vi,
// 		// 'th': locales.th,
// 	}
// });
const messages = {
				'zh-TW':locales.zh,
				'es':locales.es,
				'en':locales.en,
				'id':locales.id,
				'pt':locales.pt,
    		// 'zh-CN': locales.zh,
    		// 'zh-TW': locales.tw,
    		// 'en': locales.en,
    		// 'fr': locales.fr,
    		// 'de': locales.de,
    		// 'ja': locales.ja,
    		// 'ko': locales.ko,
    		// 'vi': locales.vi,
    		// 'th': locales.th,
    	}
export const i18n = VueI18n.createI18n({
    locale: window.localStorage.getItem('lang') ? window.localStorage.getItem('lang') : 'en',
    messages})
