module.exports = {
    copySuccess: "Cópia bem-sucedida",
    emptyText: "Sem dados",
    invite: {
        copyLink: "Link de cópia",
        dailyRewardsWillBeCalculatedBasedOnYourSubordinate: "3. As recompensas diárias serão calculadas com base na produção diária de mineração do seu subordinado",
        example: "Exemplo",
        example4: "Compartilhe seu link de promoção exclusivo por meio de vários softwares sociais. \nAmigos ingressam no node através do seu link exclusivo e você receberá renda de amigo (8,00%). \nSeu amigo convida o amigo dele para entrar no nó, você pode obter a renda do amigo dele (8,00%)",
        invitationReward: "Recompensa de convite",
        invite: "Convidar",
        inviteAndReferYourFriendsToJoinYouWillGetUpToThreeTiersOfRewards: "1. Convide e indique seus amigos para participar - você receberá até três níveis de recompensas",
        newGenerationMobileNodeMining: "Mineração de Node Móvel de Nova Geração",
        theThreeTierRewardIsLevel2: "2. A recompensa de três níveis é Nível 1 (8,00%) Nível 2 (8,00%)"
    },
    languages: {
        settings: "Configurações de linguagem"
    },
    notConnnect: "Nenhuma carteira conectada",
    notData: "- Sem dados relevantes -",
    pullReload: "Puxe para baixo para atualizar",
    record: {
        approved: "Aprovado",
        describe: "descrever",
        earningDetails: "Detalhes de renda",
        quantity: "quantidade",
        teamDetails: "Equipes",
        time: "tempo",
        withdrawalDetails: "Detalhes de retirada"
    },
    secondary: "Segunda categoria",
    tabBar: {
        home: "primeira página",
        investment: "investir",
        mine: "meu",
        service: "Servir",
        team: "equipe"
    },
    team: {
        registrationTime: "Registrar horário",
        userRevenue: "Receita do usuário"
    },
    unline: "~ Não há mais dados ~",
    updates: "Atualizações de lançamento",
    userCenter: {
        cancel: "Cancelar",
        cancelled: "Você cancelou",
        confirm: "confirme",
        earnCommission: "ganhe comissão",
        inputWithdrawAmount: "Por favor insira o valor do saque",
        insufficientFunds: "Fundos insuficientes para retirada",
        invite: "convidar",
        inviteFriends: "convide amigos",
        loading: "carregando",
        max: "máximo",
        minerFeeNotice: "Você precisa pagar a taxa de mineração para obter os benefícios. Certifique-se de ter BNB suficiente em sua carteira como taxa de mineração.",
        verificationSentToTrustWallet: "Verificação enviada para Trust Wallet. \nPor favor, verifique e processe!",
        withdraw: "retirar",
        withdrawWallet: "Retirar carteira",
        withdrawalAmount: "Quantidade retirada",
        withdrawalRecord: "Registro de retiradas"
    },
    userIndex: {
        address: "endereço",
        currentHashrate: "Poder de computação atual",
        hashAid: "Ajudante de hash",
        helpCenter: "Centro de ajuda",
        invite: "convidar",
        mining: "Mineração",
        miningTips: "O saldo atual da carteira é 0 e você pode obter 0 renda todos os dias. \nVocê pode aumentar o saldo da sua carteira a qualquer momento para",
        participant: "participantes",
        participants: "Participantes",
        participate: "Participar",
        quantity: "quantidade",
        receive: "Receber",
        stakingAPY: "Rendimento diário",
        stop: "Parar",
        todayOutput: "Saída de hoje",
        totalOutput: "produção total",
        totalParticipation: "número total de participantes",
        userBenefits: "Benefícios do usuário",
        userRevenue: "Benefícios do usuário",
        walletBalance: "saldo da carteira",
        yesterdayOutput: "Saída de ontem"
    },
    userService: {
        cooperationPlatform: "Plataforma de Cooperação",
        defiPlatform: "Comprometido em construir a maior plataforma DeFi integrada do mundo de DEX, IMO e DAO",
        gradingIncome: "Benefícios classificados",
        income: "renda",
        level: "nota",
        lowEntryBarrier: "Baixo limiar",
        noNeedTransfer: "Não há necessidade de transferir moedas",
        professionalStability: "Profissional e estável",
        projectFeatures: "Recursos do projeto",
        safeAndSecure: "Seguro e confiável",
        sharingNodeMiningRevenue: "Compartilhe a receita de mineração de nós",
        stableIncome: "Renda estável",
        stableOperation: "Equipe profissional, operação estável durante todo o ano",
        usdEthwAiStrategyC: "Pool de mineração USD ETHW AI Strategy-C",
        usdtDeposit: "Não há necessidade de transferir moeda, o USDT é depositado em sua própria carteira sem risco",
        yield: "taxa de retorno"
    },
    userTeam: {
        myEarnings: "meus ganhos",
        myTeam: "meu time",
        people: "pessoas",
        seeAll: "ver tudo",
        todayEarnings: "Os ganhos de hoje",
        totalPeople: "total de pessoas",
        totalRevenue: "Rendimento total",
        yesterdayEarnings: "Lucro de ontem"
    },
    viewDetail: "verifique os detalhes",
    kef: "atendimento ao Cliente",
    helpCenter:[
        {
            title: "Anúncio vencedor do mineiro",
            content: "Objeto de anúncio vencedor do mineiro:Todos os membros que se inscreveram com sucesso na mineração Regras:O sistema de mineração de nós da empresa sorteia seis vencedores sortudos todas as semanas Tempo:2024.1.1~~2024.12.31 Primeiro prémio(1 pessoa) :177777 USDT Segundo prémio(2 pessoas ):80000 USDT Terceiro prémio(3 pessoas):30000 USDT Contacte o serviço de apoio ao cliente online para obter detalhes sobre as seguintes quantidades de recompensa:1777 2777 3777 5777 7777 17777 Condições de resgate:O primeiro prémio requer 18.888 USDT para participar na mineração, o segundo prémio requer 7.777 USDT para participar na mineração, e o terceiro prémio requer 5.555 USDT para participar na mineração."
        },
        {
            title: "O que é (USDT)?",
            content: "USDT é o nome completo de Finanças Descentralizadas em inglês, que é \"finanças descentralizadas\", também conhecido como\"financiamento aberto\".USDT não requer intermediários fiáveis, e os beneficiários incluem pessoas que não conseguiram obter tais serviços financeiros antes. Portanto,USDT Um mercado de capitais maduro foi estabelecido,e quase todos os projetos DeFi são atualmente realizados na blockchain Ethereum."
        },
        {
            title: "Como aderir ao Smart Mining (USDT)?",
            content: "1. Primeiro, precisa de descarregar Binance Exchange e Trust Wallet para o seu telemóvel e completar o registo. 2. Em seguida, compre USDT na Binance Exchange e transfira-o para a sua Trust Wallet para obter rendimentos de mineração: 4% -20% ( rendimento diário .3. (USDT)a mineração é inteligente, não precisa de minerar sozinho. 4.Só precisa de receber o rendimento gerado pelo saldo da carteira todos os dias."
        },
        {
            title: "O USDT é arriscado?",
            content: "O USDT é um ecossistema de aplicações baseado em blockchain. Oferece uma gama de serviços financeiros semelhantes aos oferecidos por bancos tradicionais, corretores de seguros e outros intermediários financeiros fundos ou utilizá-los para fornecer liquidez numa variedade de plataformas descentralizadas e receber juros regularmente. ."
        },
        {
            title: "Os fundos estão seguros?",
            content: "Utilizamos a Binance, que é considerada uma das bolsas de criptomoedas mais seguras do setor. Possui segurança em camadas, o que torna extremamente baixas as hipóteses de um hack ou roubo bem-sucedido. contas contra o acesso não autorizado e atrasar quaisquer transações ou transferências não autorizadas. E haverá 12 mnemónicas quando se registar e iniciar sessão, desde que não revele a sua chave privada, é a única pessoa no mundo que pode negociá-lo e controlá-lo. lo."
        },
        {
            title: "Como desistir?",
            content: "Utilizamos a Binance, que é considerada uma das bolsas de criptomoedas mais seguras do setor. Possui segurança em camadas, o que torna extremamente baixas as hipóteses de um hack ou roubo bem-sucedido. contas contra o acesso não autorizado e atrasar quaisquer transações ou transferências não autorizadas. E haverá 12 mnemónicas quando se registar e iniciar sessão, desde que não revele a sua chave privada, é a única pessoa no mundo que pode negociá-lo e controlá-lo. lo."
        },
        {
            title: "Porque precisamos de pagar aos mineiros para levantar os lucros?",
            content: "Porque no processo de participação na mineração, o cliente conta apenas o nome, e a taxa do mineiro foi paga pelos mineiros. A receita gerada está, na verdade, num conjunto de contratos, e apenas a taxa do mineiro pode ser trocada por receita, que só precisa de ser paga uma vez."
        },
        {
            title: "Como faço para participar?",
            content: "1.Create a trust wallet 2.Enter the official website through Google Chrome and link to wallettrust polychain 3.Connect polyne to USDT and deposit it into the wallet 4.Your wallet must have more than 10USDT of polyne as mining fee to participate in this projeto."
        },
        {
            title: "O que é a mineração de liquidez em USDT?",
            content: "A mineração de liquidez é um tipo de finanças descentralizadas Defi. de moeda firmes, a mineração de liquidez é uma forma de obter rendimentos e acumular mais criptomoedas sem negociar."
        }
    ]
}