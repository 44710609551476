import { createApp } from 'vue'
const VueRouter = require('vue-router')
import MainLayout from '../components/MainLayout.vue'
import App from '../App.vue'
const Vue = createApp(App)

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'MainLayout',
        component: MainLayout,
        redirect: '/home',
        children: [
            { path: '/home', name: 'home', component: () => import('@/views/home/Home.vue')},
            { path: '/team', name: 'team', component: () => import('@/views/team/Team.vue')},
            { path: '/service', name: 'service', component: () => import('@/views/service/Service.vue')},
            { path: '/mine', name: 'mine', component: () => import('@/views/mine/Mine.vue')},
        ]
    },
    {
        path: '/home/invite',
        name: 'invite',
        component: () => import('@/views/home/Invite.vue'),
        meta: { index: 0 }
    },
    {
        path: '/team/earning-detail',
        name: 'earning-detail',
        component: () => import('@/views/team/EarningDetail.vue'),
        meta: { index: 0 }
    },
    {
        path: '/team/team-detail',
        name: 'team-detail',
        component: () => import('@/views/team/TeamDetail.vue'),
        meta: { index: 0 }
    },
    {
        path: '/mine/withdraw-record',
        name: 'withdraw-record',
        component: () => import('@/views/mine/WithdrawRecord.vue'),
        meta: { index: 0 }
    },
    {
        path: '/home/message',
        name: 'message',
        component: () => import('@/views/home/Message.vue'),
        meta: { index: 0 }
    },
    {
        path: '/home/kf',
        name: 'kf',
        component: () => import('@/views/home/Kf.vue'),
        meta: { index: 0 }
    },
    
]

const router = VueRouter.createRouter({
    // history: VueRouter.createWebHashHistory(),
  history: VueRouter.createWebHistory(),
  routes
})

// 解决路由重复点击报错问题
const originalPush = VueRouter.push

// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }


export default router
