import router from "@/router"
// {resetRouter}
const global = {
	//判断是否外链
	isExternal(path) {
		return /^(https?:|mailto:|tel:)/.test(path)
	},
	// 是否展示公告 0 不需要展示  1需要展示  localStorage.getItem('isShowNotice') ? localStorage.getItem('isShowNotice') : 
	isShowNotice: '1',

	// 是否是App
	isApp: 0,

	currency: {
		fixed: 0,
		create_time: 0,
		currency_from: "",
		currency_to: "",
		modify_time: 0,
		rate: "",
		symbol: "",
		status: 0
	},

	setCurrency(obj) {
		console.log('申购js', obj)
		global.currency = obj
	},

	loadedLanguages: [
		"zh-CN",
		"zh-TW",
		"en",
		"de",
		"fr",
		"ko",
		"ja",
		"vi",
		"th",
	],

	setLanguage(lang) {
		window.localStorage.setItem('lang', lang)
		global.lang = lang
	},

	lang: window.localStorage.getItem('lang') ? window.localStorage.getItem('lang') : 'en',

	config: {
		default_lang: "en",
		langList: [{
				code: "zh-CN",
				alias: "SimplifiedChinese",
				name: "简体中文",
			}, {
				code: "zh-TW",
				alias: "MultipliedChinese",
				name: "繁体中文",
			}, {
				code: "en",
				alias: "en",
				name: "English",
			},{
				code: "de",
				alias: "de",
				name: "Deutsch",
			},{
				code: "fr",
				alias: "fr",
				name: "Français",
			},{
				code: "ko",
				alias: "ko",
				name: "한국인",
			},{
				code: "ja",
				alias: "ja",
				name: "日语",
			},{
				code: "vi",
				alias: "vi",
				name: "Việt Nam",
			},{
				code: "th",
				alias: "th",
				name: "ภาษาไทย",
			}
		],
		webLogo: {
			logo: ''
		},
		system: {
			Lang: "en",
			RegMode: "",
			Version: "1.0",
			WebTitle: "",
			currency: "",
			kefuUrl: "",
			kefuWeiXin: "",
			kefu_qq: "",
			single_amount: ""
		}
	},

	token: localStorage.getItem('appToken'),

	userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {}, //用户信息

	allSliceFlag: false, //是否静音

	autoPlayFlag: false, //是否自动播放过一次

	loadingShow: false, //加载动画

	// 设置token
	setToken(token) {
		window.localStorage.setItem('appToken', token)
		global.token = token
	},

	// 设置用户信息
	setUserInfo(obj) {
		window.localStorage.setItem('userInfo', JSON.stringify(obj))
		global.userInfo = obj
	},

	//退出登录
	logout() {
		localStorage.removeItem('appToken')
		localStorage.removeItem('userInfo')
		localStorage.removeItem('orderMusic')
		localStorage.removeItem('isShowNotice')
		global.token = null
		global.MenuList = []
		global.ApiList = []
		router.push('/login')
	},
}

export default global
