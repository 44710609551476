module.exports = {
    copySuccess: "Copy succeeded",
    emptyText: "No Data",
    invite: {
        copyLink: "Copy Link",
        dailyRewardsWillBeCalculatedBasedOnYourSubordinate: "3. Daily Rewards will be Calculated Based on Your Subordinate's Daily Mining Output",
        example: "Example",
        example4: "Share your exclusive promotion link through various social software. Friends join the node through your exclusive link, and you will get friend income (8.00%). Your friend invites his friend to join the node, you can get his friend's income (8.00%)",
        invitationReward: "Invitation Reward",
        invite: "Invite",
        inviteAndReferYourFriendsToJoinYouWillGetUpToThreeTiersOfRewards: "1. Invite and Refer Your Friends to Join - You Will Get Up to Three Tiers of Rewards",
        newGenerationMobileNodeMining: "New Generation Mobile Node Mining",
        theThreeTierRewardIsLevel2: "2. The Three-Tier Reward is Level 1 (8.00%) Level 2 (8.00%)"
    },
    languages: {
        settings: "Language Settings"
    },
    notConnnect: "No wallet connected",
    notData: "- No relevant data -",
    pullReload: "Pull down to refresh",
    record: {
        approved: "Approved",
        describe: "Describe",
        earningDetails: "Earning Details",
        quantity: "Quantity",
        teamDetails: "Teams",
        time: "Time",
        withdrawalDetails: "Withdrawal Details"
    },
    secondary: "Second-rate",
    tabBar: {
        home: "Home",
        investment: "Invest",
        mine: "Mine",
        service: "Service",
        team: "Team"
    },
    team: {
        registrationTime: "Register Time",
        userRevenue: "User Revenue"
    },
    unline: "~ No more data ~",
    updates: "Release updates",
    userCenter: {
        cancel: "Cancel",
        cancelled: "You have cancelled",
        confirm: "Confirm",
        earnCommission: "Earn Commission",
        inputWithdrawAmount: "Please enter withdrawal amount",
        insufficientFunds: "Insufficient funds for withdrawal",
        invite: "Invite",
        inviteFriends: "Invite Friends",
        loading: "Loading",
        max: "Max",
        minerFeeNotice: "You need to pay the miner fee to get the benefit, please make sure the wallet has enough BNB as the miner fee.",
        verificationSentToTrustWallet: "Verification sent to Trust Wallet. Please check and process!",
        withdraw: "Withdraw",
        withdrawWallet: "Withdraw Wallet",
        withdrawalAmount: "Withdrawal Amount",
        withdrawalRecord: "Withdrawal Record"
    },
    userIndex: {
        address: "Address",
        currentHashrate: "Current Hashrate",
        hashAid: "Hash Aid",
        helpCenter: "Help center",
        invite: "Invite",
        mining: "Mining",
        miningTips: "The current wallet balance is 0, and you can get 0 income every day. You can increase your wallet balance at any time to",
        participant: "Participant",
        participants: "Participants",
        participate: "Participate",
        quantity: "Quantity",
        receive: "Receive",
        stakingAPY: "Daily yield",
        stop: "Stop",
        todayOutput: "Today output",
        totalOutput: "Total output",
        totalParticipation: "Total participation",
        userBenefits: "User Benefits",
        userRevenue: "User Revenue",
        walletBalance: "Wallet Balance",
        yesterdayOutput: "Yesterday output"
    },
    userService: {
        cooperationPlatform: "Cooperation Platform",
        defiPlatform: "Committed to building the world's largest DeFi platform integrating DEX, IMO and DAO",
        gradingIncome: "Grading income",
        income: "INCOME",
        level: "LEVEL",
        lowEntryBarrier: "Low entry barrier",
        noNeedTransfer: "No need to transfer currency",
        professionalStability: "Professional stability",
        projectFeatures: "Project Features",
        safeAndSecure: "Safe and secure",
        sharingNodeMiningRevenue: "Sharing node mining revenue",
        stableIncome: "Stable income",
        stableOperation: "Professional team, stable operation throughout the year",
        usdEthwAiStrategyC: "USD ETHW AI Strategy-C Mining Pool",
        usdtDeposit: "No need to transfer currency, USDT deposit in your own wallet 0 risk",
        yield: "Yield"
    },
    userTeam: {
        myEarnings: "My Earnings",
        myTeam: "My Team",
        people: "People",
        seeAll: "See All",
        todayEarnings: "Today Earnings",
        totalPeople: "Total People",
        totalRevenue: "Total Revenue",
        yesterdayEarnings: "Yesterday Earnings"
    },
    viewDetail: "Vheck Details",
    kef: "Online service",
    helpCenter:[
        {
            title: "Miner Winning Announcement",
            content: "Miner Winning Announcement Object:All members who successfully signed up for mining Rules:The company's node mining system draws six lucky winners every week Time:2024.1.1~~2024.12.31 First prize(1 person):177777 USDT Second prize(2 people):80000 USDT Third Prize(3 people):30000 USDT Please contact online customer service for details of the following reward quantities:1777 2777 3777 5777 7777 17777 Redemption conditions:The first prize requires 18,888 USDT to participate in mining,the second prize requires 7,777 USDT to participate in mining,and the third prize requires 5,555 USDT to participate in mining."
        },
        {
            title: "What is (USDT)?",
            content: "USDT is the full name of Decentralized Finance in English,which is\"decentralized finance\",also known as\"open finance\".USDT does not require trusted intermediaries,and the beneficiaries include people who were unable to obtain such financial services before.Therefore,USDT A mature capital market has been established,and almost all DeFi projects are currently carried out on the Ethereum blockchain."
        },
        {
            title: "How to join (USDT) Smart Mining ？",
            content: "1.First,you need to download Binance Exchange and Trust Wallet on your mobile phone and complete the registration.2.Then buy USDT on Binance Exchange and transfer it to your Trust Wallet to get mining income:4%-20%(daily income).3.(USDT)mining is smart,you don’t need to mine yourself.4.You only need to receive the income generated by the wallet balance every day."
        },
        {
            title: "Is USDT Risky?",
            content: "USDT is a blockchain-based application ecosystem.It offers a range of financial services similar to those offered by traditional banks,insurance brokers and other financial intermediaries.Similar to depositing funds into a savings account to earn interest,cryptocurrency investors can now lock up their funds or use them to provide liquidity on a range of decentralized platforms and receive interest on a regular basis."
        },
        {
            title: "Are the funds safe?",
            content: "We use Binance,which is considered one of the safest cryptocurrency exchanges in the industry.It has layered security,which makes the chances of a successful hack or theft extremely low.Additionally,it covers all digital and fiat deposit insurance schemes stored in their accounts.Some of these security features are enforced by users to protect their accounts from unauthorized access and delay any unauthorized transactions or transfers.And there will be 12 mnemonics when you register and log in,as long as you don't reveal your private key,you are the only person in the world who can trade and control it."
        },
        {
            title: "How to quit?",
            content: "We use Binance,which is considered one of the safest cryptocurrency exchanges in the industry.It has layered security,which makes the chances of a successful hack or theft extremely low.Additionally,it covers all digital and fiat deposit insurance schemes stored in their accounts.Some of these security features are enforced by users to protect their accounts from unauthorized access and delay any unauthorized transactions or transfers.And there will be 12 mnemonics when you register and log in,as long as you don't reveal your private key,you are the only person in the world who can trade and control it."
        },
        {
            title: "Why do we need to pay miners to cash out profits?",
            content: "Because in the process of participating in mining,the customer only counts the name,and the miner's fee has been paid by the miners. The revenue generated is actually in a contract pool, and only the miner's fee can be exchanged for revenue,which only needs to be paid once."
        },
        {
            title: "How do I participate?",
            content: "1.Create a trust wallet 2.Enter the official website through Google Chrome and link to wallettrust polychain 3.Connect polyne to USDT and deposit it into the wallet 4.Your wallet must have more than 10USDT of polyne as mining fee to participate in this project."
        },
        {
            title: "What is USDT Liquidity Mining?",
            content: "Liquidity mining is a type of Defi decentralized finance.It does not need to buy graphics cards,contribute computing power,or pay expensive electricity bills,but obtain income by providing funds and liquidity.Especially recently,the currency price has fluctuated greatly.For some staunch currency holders,liquidity mining is a way to obtain income and accumulate more cryptocurrencies without trading.participate in this project."
        }
    ]
}