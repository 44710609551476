<template>
    <div class="online-person" @click="$router.push({name:'kf'})" ref="div" :style="{'width':itemWidth+'px','height':itemHeight+'px','left':left+'px','top':top+'px'}">
            <img src="../assets/img/persononline.png" alt="" srcset="">
    </div>
</template>
<script>
export default{
    data(){
        return {
            timer:null,
        currentTop:0,
        clientWidth:0,
        clientHeight:0,
        left:0,
        top:0,
        }
    },
    props:{
      itemWidth:{
        type:Number,
        default:60
      },
      itemHeight:{
        type:Number,
        default:60
      },
      gapWidth:{
        type:Number,
        default:10
      },
    
      coefficientHeight:{
        type:Number,
        default:0.8
      }
    },
    created(){
        this.clientWidth = document.documentElement.clientWidth;
      this.clientHeight = document.documentElement.clientHeight;
      this.left = this.clientWidth - this.itemWidth - this.gapWidth;
      this.top = this.clientHeight*this.coefficientHeight;
    },mounted(){
        this.$nextTick(()=>{
        const div = this.$refs.div;
        div.addEventListener("touchstart",(e)=>{
                e.stopPropagation();
          div.style.transition = 'none';
        });
        div.addEventListener("touchmove",(e)=>{
                e.stopPropagation();
          if (e.targetTouches.length === 1) {
            let touch = event.targetTouches[0];
            this.left = touch.clientX - this.itemWidth/2;
            this.top = touch.clientY - this.itemHeight/2;
          }
        },
        false
        );
        div.addEventListener("touchend",(e)=>{
            e.stopPropagation();
          div.style.transition = 'all 0.3s';
           if(this.left>this.clientWidth/2){
             this.left = this.clientWidth - this.itemWidth - this.gapWidth;
           }else{
             this.left = this.gapWidth;
           }
           if(this.top<=36)
           {
               this.top=36+this.gapWidth
           }
           else{
               let bottom=this.clientHeight-50-this.itemHeight-this.gapWidth
               console.log(bottom,this.top)
               if(this.top>=bottom)
               {
                   this.top=bottom
               }

           }
        });
      });
    },
    methods:{
      onBtnClicked(){
        this.$emit("onFloatBtnClicked");
      },
  
    },
}
</script>
<style lang="scss">
.online-person{
    position: fixed;
    right: 0;
    bottom: 40%;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    z-index: 999;
    img{
        width: inherit;
        height: inherit;
        border-radius: inherit;
    }
}
</style>